.DecorationItem {
  display: flex;
  align-items: center;
}

.DecorationBorder {
  height: 70px;
  border-left: solid;
  border-left-width: 1px;
  border-color: #E3E7EA;
}

.DecorationCircle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 30px;
  text-align: center;

  border:2px solid white;
  cursor: pointer;
}

.DecorationCircle:hover:not(.selected) {
  border-color: var(--grey-highlight);
}

.DecorationCircle.selected {
  border-color: var(--blue);
}
