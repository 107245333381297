.CategoryItem {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 30px;

  background-color: #e3e7ea;
  border:2px solid #e3e7ea;
  text-align: center;

  cursor: pointer;
}

.CategoryItem:hover:not(.selected) {
  border-color: var(--grey-highlight);
}

.CategoryItem.selected {
  background-color: #9eaab6;
  border-color: var(--blue);
}

.preview-img {
  width: 60px;
  margin: 10px auto;
}

p.category {
  margin-top: 10px;
}
