.TopBar {
  width: 100%;
  height: 70px;
  background-color: black;
  display: flex;
  align-items: center;
}

p.title {
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin-left: 20px;
}
