.DecorationBar {
  height: 100px;
  margin-top: 0;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  list-style: none;
  background-color: #ffffff;
  box-shadow: 0 5px 5px #c7c7c7;
}

.chevron {
  color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 30px;
  font-size: 38px;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

.chevron:hover {
  color: rgb(80, 79, 79);
}

.hide {
  color: white;
}

.hide:hover {
  color: white;
}
