.PreviewArea {
  width: 100%;
  flex: 1;

  position: relative;
}

.preview-image {
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  width: auto;
  height: 100%;
}
