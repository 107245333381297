body {
  margin: 0;
  padding: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  letter-spacing: 0.0125rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  cursor: pointer;
}

/* Set shared vars */
:root {
  --blue: #10069F;
  --blue-highlight: #1709E9;
  --grey: #354858;
  --grey-highlight: #9eaab6;

}

* {
  outline-color: var(--blue);
}
